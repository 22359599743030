<template>
  <div class="partnership">
    <div class="banner">
      <!-- <img src="/image/partnership/incabin.png" alt="" /> -->
      <ImageWithPlaceholder
        src="/image/partnership/partnership.png"
        alt="Loaded Image"
      />
    </div>
    <div class="partnership-top">
      <div class="content-wrapper">
        <h1-title> Partnership </h1-title>
        <p class="partnership-top-text">
          Ever since its founding, ArcSoft has developed very good relationships
          with partners of imaging sensor and camera module makers, smartphone
          chip makers, smart car chip makers and Tier 1 and Tier 2 automotive
          suppliers. With deep knowledge and extensive engineering experiences
          in the whole pipelines of visual sensing and processing, ArcSoft has
          the unique ability of helping OEM customers to deliver the best
          products of high performance with overall lower hardware costs, and
          shorter time to the market.<br />
          During its 30 years life, ArcSoft has firmly believed that a good
          product consists of not only cutting edge technology, but also
          professional engineering work and excellent customer services. By
          utilizing ArcSoft's innovative software and professional engineering
          services, OEM customers have shipped billions of products embedded
          with ArcSoft core technologies in the form of software.
        </p>
      </div>
    </div>
    <div class="partnership-bottom">
      <div class="content-wrapper">
        <h2>Good Product = Advanced Technology + Engineering</h2>
        <div>
          <img src="/image/partnership/engineering.png" alt="" />
        </div>
      </div>
    </div>
    <div class="ecosystem">
      <div class="content-wrapper">
        <h1-title :mb="48">
          ArcSoft Vision Solutions Partner Ecosystem
        </h1-title>
        <div class="content-item">
          <h2>OEM Customers</h2>
          <div>
            <img src="/image/partnership/1.png" alt="" />
          </div>
        </div>
        <div class="content-item">
          <h2>Platform Partners</h2>
          <div>
            <img src="/image/partnership/2.png" alt="" />
          </div>
        </div>
        <div class="content-item partners">
          <h2>Image Sensor and Camera Module Partners</h2>
          <div>
            <img src="/image/partnership/3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  name: 'Partnership',
  metaInfo: {
    title: 'ArcSoft Partners & Customers ',
    meta: [
      {
        name: 'description',
        content:
          "ArcSoft's network of partners and customers who drive innovation and success across industries. Our collaborations with leading technology firms and brands highlight our commitment to delivering cutting-edge solutions and achieving mutual growth. ",
      },
      {
        name: 'keywords',
        content:
          'ArcSoft partners, ArcSoft customers, technology collaborations, industry partnerships, success stories, innovation partnerships, client testimonials, business collaborations, strategic alliances', // 关键词
      },
    ],
  },
  components: { ImageWithPlaceholder },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
}

img {
  width: 100%;
}

.partnership-top {
  margin-top: 80px;
  margin-bottom: 45px;
}

.partnership-bottom {
  background-color: #fafafa;
  padding: 40px 40px 62px 40px;
  margin-bottom: 80px;
  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 22px;
  }
}

.ecosystem {
  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 38px;
    color: #222;
  }
  .content-item {
    margin-bottom: 60px;
    &.partners {
      h2 {
        margin-bottom: 69px;
      }
    }
  }
}
.partnership-top-text {
  padding: 0 24px;
}
.content-wrapper {
  width: 960px;
  margin: 0 auto;
}
</style>
